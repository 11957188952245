import logoLink from '../resources/efaflex.svg';

export const cssVariables = {
    
    primary: '#FF8C00',
    primary_light: '#FFC073',
    primary_dark: '#E37D00',
    primary_transparent: '#cccccc',
    text_on_primary: 'white',
    
    secondary: '#4a4a4a',
    secondary_light: '#7e7e7e',
    secondary_transparent: '#f2f2f2',
    text_on_secondary: 'white',
    
    font: 'Arial, Helvetica, sans-serif',
    link_color: '#FF8C00',
    font_color: '#000',

    //border_radius: '0px',
    border_width: '1px',
    border_style: 'solid',

    active: '#8cc800',
    inactive: '#cccccc',

    edit_color: '#FF8C00',
    activate_color: '#a6a6a6',
    deactivate_color: '#a6a6a6',
    delete_color: '#4a4a4a',
    
    dropdown_background: '#FFC073',
};

export const logo = logoLink;

import React from 'react';
import './LoadingSpinner.scss';

function Loading() {
    return (
        <div className="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
}

export default Loading;

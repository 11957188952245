import React from 'react';

function France() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480">
            <g>
                <path fill="#F0F0F0" d="M0 0h640v480H0z" />
                <path fill="#00267f" d="M0 0h213.3v480H0z" />
                <path fill="#f31830" d="M426.7 0H640v480H426.7z" />
            </g>
        </svg>
    );
}

export default France;
